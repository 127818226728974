import styled from 'styled-components';
import quemSomosFoto from 'assets/quem-somos-livros.jpg';

export const ContentHolder = styled.div`
  width: 100%;

  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;

  margin-bottom: -100px;

  @media (max-width: 768px) {
    & {
      flex-direction: column;
      margin-bottom: 0;
    }
  }
`;

export const PhotoHolder = styled.div`
  width: 50%;
  height: 100%;
  min-height: 800px;

  background: url(${quemSomosFoto});
  background-repeat: no-repeat;
  background-size: auto 100%;
  background-position: 25%;

  display: flex;
  justify-content: flex-end;
  align-items: center;

  h1 {
    background-color: #fff;
    color: var(--main-bg);
    font-size: 48px;
    padding: 10px 50px;
    box-shadow: 0 0 20px rgba(0, 0, 0, 0.3);
  }

  @media (max-width: 768px) {
    & {
      width: 100%;
      min-height: 250px;
      justify-content: center;
      align-items: center;
      h1 {
        max-width: 90%;
        font-size: 32px;
        text-align: center;
      }
    }
  }
`;

export const TextHolder = styled.div`
  width: 50%;
  height: 100%;
  min-height: 600px;

  margin-right: 100px;

  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;

  border-radius: 0 10px 10px 0;

  padding: 30px;

  background: #fff;
  box-shadow: 0 0 20px rgba(0, 0, 0, 0.3);

  p {
    font-size: 20px;
    margin: 10px 0;
    text-align: justify;
    &:last-child {
      margin-bottom: 0px;
    }
  }

  a {
    background-color: var(--main-color);
    color: var(--main-bg);
    font-weight: 600;
    font-size: 20px;
    padding: 15px 30px;
    border-radius: 30px;

    margin-top: 50px;
  }

  @media (max-width: 768px) {
    & {
      width: 90%;
      min-height: auto;

      margin-right: 0;
      border-radius: 0 0 10px 10px;

      p {
        text-align-last: center;
      }

      a {
        width: 100%;
        text-align: center;
      }
    }
  }
`;
