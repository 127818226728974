import React from 'react';
import { PageProps } from 'gatsby';
import Layout from 'components/_layout';
import {
  ContentHolder,
  PhotoHolder,
  TextHolder,
} from '../styles/pages/quem-somos';

const Contact = (props: PageProps) => {
  const { location } = props;

  return (
    <Layout pathname={location.pathname}>
      <ContentHolder>
        <PhotoHolder>
          <h1>Sobre a FG Livraria</h1>
        </PhotoHolder>
        <TextHolder>
          <p>
            Sendo pai, avô e empresário sei da necessidade de facilitar esse
            momento da compra de materiais escolares.
          </p>
          <p>
            A FG Livraria surgiu da ideia de desburocratizar esse momento,
            facilitando a vida dos pais, deixando os filhos felizes com a compra
            dos livros do próximo ano e sem nenhuma preocupação.
          </p>
          <p>
            Afinal, nossas vidas já são tão corridas e precisamos gastar nosso
            tempo com o que realmente importa, não é mesmo?
          </p>
          <a href="/contato">Entre em contato e saiba mais</a>
        </TextHolder>
      </ContentHolder>
    </Layout>
  );
};

export default Contact;
